import React from "react"

function Ornament() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 109.68 52.01"
        className="fill-current"
      >
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path d="M0,26C0,8.17,13.65,0,22.23,0c6.2,0,15.19,3.51,15.19,11.88a7.75,7.75,0,0,1-7.34,7.86C25.53,19.86,22,17,22,13.34c0-1.86.72-2.79,3.51-4.75a5.39,5.39,0,0,0,1.87-3.1c0-1.66-2.69-2.49-6.72-2.38-7.34.2-14,7.65-14,14,0,12.1,12.09,14,16.85,15.2,4.55,1.24,5,4.34,5,5.69a10.37,10.37,0,0,1-.82,4.65,8.79,8.79,0,0,0-.94,3.62c0,.93,1.14,1.44,2.9,1.44,7.24,0,14.68-8.68,20.57-17A8.11,8.11,0,0,1,48.48,26a7.48,7.48,0,0,1,7-7.44,8.51,8.51,0,0,1,2.9.52C67.4,6.31,74,.32,85.49,0c13.34-.31,24.19,10.33,24.19,26.15,0,17.06-11.16,25.64-21.4,25.85-6.72.1-14.36-3.21-14.36-10.14,0-4.23,3.2-8.16,7.34-8.16,5.68,0,7.23,4,7.23,6.51A4.22,4.22,0,0,1,87,43.63a2.71,2.71,0,0,0-1.34,2.48c0,1.14,1.55,2.69,4.34,2.59,4.65-.11,13.54-4.45,13.54-14.48,0-8.89-3.41-11.37-13.12-14.16-5.59-1.55-8-3.41-8-6.3a15.59,15.59,0,0,1,1-4.86,14.89,14.89,0,0,0,.62-3.1c0-.93-.83-1.66-2.79-1.66-7.34.21-13.44,7.75-20,17.06a7.17,7.17,0,0,1,1.75,4.44,7.47,7.47,0,0,1-7.23,7.65,7.18,7.18,0,0,1-2.59-.41C45.28,43.94,37.22,51.59,25,52,9.72,52.42,0,39.08,0,26Z" />
          </g>
        </g>
      </svg>
    </div>
  )
}

export default Ornament
