import React from "react"

export default function Teatermeny({ teatermeny }) {
  const { rubrikTeatermeny, beskrivningTeatermeny } = teatermeny
  return (
    <div className="flex flex-col gap-4">
      <div>
        <h2 className="mb-0">{rubrikTeatermeny}</h2>
      </div>
      <div
        className="wp-content"
        dangerouslySetInnerHTML={{ __html: beskrivningTeatermeny }}
      />
    </div>
  )
}
