import React from "react"

export default function Middag({ middag }) {
  const { rubrikMiddagRestaurang, beskrivningMiddagRestaurang } = middag
  return (
    <div>
      <h2>{rubrikMiddagRestaurang}</h2>
      <div
        dangerouslySetInnerHTML={{ __html: beskrivningMiddagRestaurang }}
        className="wp-content"
      />
    </div>
  )
}
