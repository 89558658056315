import React from "react"
import HeaderFeaturedImage from "../common/HeaderFeaturedImage"
import Lunch from "./Lunch"
import Middag from "./Middag"
import RestaurangGraphic from "./RestaurangGraphic"
import RestaurangMenu from "./RestaurangMenu"
import Teatermeny from "./Teatermeny"

export default function RestaurangSinglePage({ page, lang }) {
  const { title, featuredImage, restauranginfo } = page
  const {
    textIngressRestaurang,
    rubrikSidhuvudRestaurang,
    rubrikIngressRestaurang,
    lunchRestaurang,
    teatermenyRestaurang,
    middagResturang,
  } = restauranginfo

  return (
    <div>
      {featuredImage && (
        <HeaderFeaturedImage
          featuredImage={featuredImage}
          title={lang === "sv" ? "Restaurang" : "Restaurant"}
          // subTitle={rubrikSidhuvudRestaurang}
        />
      )}

      <div className="">
        <RestaurangMenu lang={lang} />
      </div>
      <div className="max-w-4xl mx-auto px-4 lg:px-8 py-12 flex flex-col gap-4 lg:gap-8 relative">
        <div className="lg:text-2xl">
          <div dangerouslySetInnerHTML={{ __html: textIngressRestaurang }} />
        </div>

        <div className="flex  flex-col md:flex-row gap-8">
          <div className="flex flex-col gap-4 lg:gap-8">
            <div id="lunch" className="flex-1">
              <Lunch lunchRestaurang={lunchRestaurang} />
            </div>
            <div className="flex flex-col gap-4 lg:gap-8 bg-primary text-white p-4 lg:p-8">
              <div id="teatermeny">
                <div className="">
                  <Teatermeny teatermeny={teatermenyRestaurang} />
                </div>
              </div>
              <div id="middag" className="flex flex-col gap-4 ">
                <Middag middag={middagResturang} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
