import React from "react"
import Layout from "../components/layout"
import RestaurangSinglePage from "../components/restaurang/RestaurangSinglePage"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"

export default function RestaurangPage() {
  const data = useStaticQuery(graphql`
    query {
      wpPage(databaseId: { eq: 9 }) {
        id
        title
        translations {
          uri
        }
        restauranginfo {
          textIngressRestaurang
          rubrikSidhuvudRestaurang
          rubrikIngressRestaurang
          lunchRestaurang {
            beskrivningLunchRestaurang
            rubrikDagensLunch
            extrainfoLunch
            rubrikLunchRestaurang
            veckomenyDagensLunch {
              rattLunch
              veckodagLunch
            }
            menySomPdf {
              mediaItemUrl
            }
          }
          teatermenyRestaurang {
            beskrivningTeatermeny
            rubrikTeatermeny
          }
          middagResturang {
            beskrivningMiddagRestaurang
            rubrikMiddagRestaurang
          }
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `)
  const page = data.wpPage
  // const translation = data.wpPage.translations[0]?.uri

  if (!page) return null
  return (
    <Layout lang="sv" translation="/en/restaurant">
      <SEO title={page.title || "Restaurangen"} />
      <>
        <RestaurangSinglePage lang="sv" page={page} />
      </>
    </Layout>
  )
}
