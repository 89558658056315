import React from "react"

import Link from "../common/Link"

export default function RestaurangMenu({ lang }) {
  const menuItems = [
    { uri: "#lunch", labelSV: "Lunch", labelEN: "Lunch" },
    { uri: "#teatermeny", labelSV: "Teatermeny", labelEN: "Theatre menu" },
    { uri: "#middag", labelSV: "Middag", labelEN: "Dinner" },
  ]

  return (
    <div className="bg-black">
      <div className="bg-primary text-white  p-4 lg:p-8 flex flex-row w-full justify-center gap-4 lg:gap-8">
        {menuItems.map((menuItem, i) => {
          return <MenuItem lang={lang} key={i} menuItem={menuItem} />
        })}
      </div>
    </div>
  )
}

function MenuItem({ menuItem, lang }) {
  const { uri, labelSV, labelEN } = menuItem
  return (
    <a
      href={uri}
      className="font-bold hover:text-gray-900"
      activeClassName="text-gray-900"
    >
      {lang === "sv" ? labelSV : labelEN}
    </a>
  )
}
