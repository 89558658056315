import React from "react"
import Link from "../common/Link"
import MarkethypeLunch from "../common/MarkethypeLunch"
import Ornament from "../common/Ornament"
import { useStaticQuery, graphql } from "gatsby"

export default function Lunch({ lunchRestaurang }) {
  const {
    beskrivningLunchRestaurang,
    rubrikDagensLunch,
    rubrikLunchRestaurang,
    extrainfoLunch,
    menySomPdf,
  } = lunchRestaurang

  const data = useStaticQuery(graphql`
    query {
      wpPage(databaseId: { eq: 9 }) {
        restauranginfo {
          lunchRestaurang {
            extrainfoLunch
            rubrikLunchRestaurang
            rubrikDagensLunch
            veckomenyDagensLunch {
              rattLunch
              veckodagLunch
            }
          }
        }
      }
    }
  `)

  const dishes =
    data.wpPage?.restauranginfo.lunchRestaurang.veckomenyDagensLunch
  const lunchRubrik =
    data.wpPage?.restauranginfo.lunchRestaurang.rubrikDagensLunch
  const lunchExtraInfo =
    data.wpPage?.restauranginfo.lunchRestaurang.extrainfoLunch
  return (
    <div className="flex flex-col gap-4 ">
      <div>
        <h2 className="mb-0">{rubrikLunchRestaurang}</h2>
      </div>
      <div className="flex flex-col gap-4  mb-4">
        <div
          className="wp-content"
          dangerouslySetInnerHTML={{ __html: beskrivningLunchRestaurang }}
        />
        {menySomPdf ? (
          <div>
            <Link
              to={menySomPdf.mediaItemUrl}
              className="underline decoration-primary"
            >
              Veckans meny som pdf
            </Link>
          </div>
        ) : null}
      </div>

      <div className="flex flex-col gap-4 bg-white border-8 border-gray-900 p-4 lg:p-12 items-center ">
        <div>
          <h3 className="mb-0">{lunchRubrik}</h3>
        </div>

        <div className="flex flex-col gap-4 justify-center items-center text-center">
          {dishes?.map((dish, i) => {
            return <Dish key={i} dish={dish} index={i} />
          })}
        </div>
        <div>
          <div className="w-12">
            <Ornament />
          </div>
        </div>
        <div className="text-center">
          <div
            className="wp-content"
            dangerouslySetInnerHTML={{ __html: lunchExtraInfo }}
          />
        </div>
      </div>
      <div className="flex flex-col gap-6 lg:gap-8 p-4 lg:p-8 bg-primary/5">
        <p>Vill du ha veckans meny i din mejlkorg varje måndag morgon? </p>
        <MarkethypeLunch />
      </div>
    </div>
  )
}

function Dish({ dish }) {
  const { rattLunch, veckodagLunch, index } = dish
  const indexOfWeekday = new Date().getDay() - 1

  const isPastDay = index < indexOfWeekday

  return (
    <div className="flex flex-col gap-2 justify-center items-center">
      <div className="w-16 lg:w-24">
        <b>{veckodagLunch}</b>
      </div>
      <div className="flex-1 italic">{rattLunch}</div>
    </div>
  )
}
